


























































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfileUpdate } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { dispatchUpdateUserProfile } from '@/store/main/actions';

@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  public password1 = '';
  public password2 = '';
  public otp_key: string = '';

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public passwordRules() {
    return [
      (v) => !!v || '請填寫密碼',
      (v) => ( !!v && v.length >= 8 ) || '密碼長度需大於 8 個字',
    ];
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      updatedProfile.password = this.password1;
      if (this.otp_key) {
         updatedProfile.otp_key = this.otp_key;
      }
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
      this.$router.push('/account');
    }
  }
}
